.custom-success {
  border-radius: 16px !important;
  background-color: rgba(180, 215, 75, 0.9) !important;
  width: 320px !important;
  padding: 8px 16px;
  @media screen and (max-width: 480px) {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.custom-error {
  border-radius: 16px !important;
  background-color: rgba(189, 136, 144, 0.9) !important;
  width: 320px !important;
  padding: 8px 16px;
  @media screen and (max-width: 480px) {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 60px;
  width: fit-content;
  border-radius: 28px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  padding: 2px 4px;
  left: 50%;
  margin-left: var(--swiper-pagination-margin, -44px);
}

span.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border: 1px solid white !important;
  border-radius: 12px;
  opacity: 1;
  background: transparent;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
}

span.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #bd8890 !important;
}

.image-swiper-button-prev {
  top: 50%;
  left: 24px;
  position: absolute;
  z-index: 99;
}

.image-swiper-button-next {
  top: 50%;
  right: 24px;
  position: absolute;
  z-index: 99;
}

/*svg {*/
/*  filter: drop-shadow(0px 0px 2px black);;*/
/*}*/
