* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
}

@font-face {
  font-family: Public-sans;
  src:
    local('Public-sans'),
    url('./assets/fonts/publicSans.ttf') format('truetype');
}

@font-face {
  font-family: NeulisAlt;
  font-weight: 400;
  src:
    local('NeulisAlt'),
    url('./assets/fonts/NeulisAlt-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  src:
    local('Montserrat'),
    url('./assets/fonts/montserrat.regular.ttf') format('truetype');
}

@font-face {
  font-family: Fellix-Regular;
  src:
    local('Fellix-Regular'),
    url('./assets/fonts/Fellix-Regular.ttf') format('truetype');
}

body,
html {
  font-family: Public-sans, sans-serif !important;
  background-color: #fafafa;
}

::-webkit-scrollbar {
  display: none;
}
